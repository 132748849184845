import { formatDate } from "@clipboard-health/date-time";
import { InternalLink, Text } from "@clipboard-health/ui-react";
import { IonList, IonText } from "@ionic/react";
import { Button, Chip } from "@mui/material";
import { SHIFT_PRIORITY_COLOR } from "@src/appV2/lib/Theme/constants";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { VerticalSpacing } from "@src/designsystem/VerticalSpacing/VerticalSpacing";
import { useState } from "react";

import { pluralizeWorkplace } from "./lang";
import { logViewFacilitiesClicked } from "./logs";
import {
  Count,
  ExpandIcon,
  Spinner,
  SubText,
  Workplace,
  WorkplaceItem,
  WorkplaceName,
} from "./PriorityAccessPage.styles";
import { Card, CardTitle, Item } from "./RatingsPage.styles";

export function PriorityAccessATeamCard() {
  const [isATeamExpanded, setIsATeamExpanded] = useState(false);
  const { userId } = useDefinedWorker();

  const { aTeamWorkplaces, isWorkerAteamEnabled, aTeamWorkplacesCount, isATeamWorkplaceLoading } =
    useGetATeamWorkplaces();

  const toggleATeam = () => {
    setIsATeamExpanded((prevState) => !prevState);
    logViewFacilitiesClicked(
      userId,
      aTeamWorkplacesCount,
      "A-Team",
      isATeamExpanded ? "listCollapsed" : "listExpanded"
    );
  };

  if (!isWorkerAteamEnabled || aTeamWorkplacesCount === 0) {
    return null;
  }

  return (
    <Card>
      <IonList lines="none">
        <Item>
          <CardTitle>Priority Plus</CardTitle>
        </Item>
        <IonText color="shift-priority">
          <Count>{aTeamWorkplacesCount}</Count>
        </IonText>
        <Item>
          <SubText>
            {pluralizeWorkplace(aTeamWorkplacesCount)}{" "}
            {aTeamWorkplacesCount === 1 ? "has " : "have "}
            added you to their A-Team due to your excellent performance! You'll receive Priority
            access to their shifts. Only a few other professionals will have this access with you.
          </SubText>
        </Item>
        <VerticalSpacing size="lg" />
        {aTeamWorkplacesCount > 0 && (
          <Button
            onClick={toggleATeam}
            variant="text"
            fullWidth
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              justifyContent: "space-between",
              paddingX: 2,
              borderTop: 1,
              borderRadius: 0,
              borderColor: theme.palette.divider,
            })}
          >
            <SubText>View Priority Plus Facilities ({aTeamWorkplacesCount})</SubText>
            <ExpandIcon>{isATeamExpanded ? "–" : "+"}</ExpandIcon>
          </Button>
        )}
        {isATeamExpanded &&
          (isATeamWorkplaceLoading ? (
            <Spinner name="dots" />
          ) : (
            Object.values(aTeamWorkplaces)?.map((workplace) => (
              <WorkplaceItem key={workplace.id}>
                <Workplace>
                  <WorkplaceName>{workplace.name}</WorkplaceName>
                  <Text variant="body2">{formatDate(workplace.recentUpdatedAt as Date)}</Text>
                  <InternalLink
                    to={`/home/facility/${workplace.id}`}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    <Chip
                      label="View Profile"
                      size="medium"
                      variant="outlined"
                      sx={{
                        color: SHIFT_PRIORITY_COLOR,
                        borderColor: SHIFT_PRIORITY_COLOR,
                        fontWeight: "600",
                      }}
                    />
                  </InternalLink>
                </Workplace>
              </WorkplaceItem>
            ))
          ))}
      </IonList>
    </Card>
  );
}
