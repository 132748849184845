import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { RatingCard, RatingCardProps } from "./RatingsCard";
import { usePreferredWorkplaceCount } from "../api/usePreferredWorkplaceCount";
import { CardLoader } from "../CardLoader";
import { pluralizeWorkplace, pluralizeWorkplaceWithThe } from "../lang";
import { logViewDetailsClicked } from "../logs";

export function PriorityAccessCard() {
  const history = useHistory();
  const { userId } = useDefinedWorker();
  const { isLoading: isPreferredWorkplaceLoading, data: preferredWorkplaceData } =
    usePreferredWorkplaceCount(userId, {
      meta: {
        userErrorMessage: "Something went wrong while loading your priority access workplaces.",
      },
    });

  const { aTeamStatus, aTeamWorkplacesCount } = useGetATeamWorkplaces();

  const handlePriorityAccessClicked = () => {
    logViewDetailsClicked(userId, "priority access");
    history.push(TabRouterPath.RATINGS_PRIORITY_ACCESS);
  };

  const handleAteamClicked = () => {
    logViewDetailsClicked(userId, "a-team");
    history.push(TabRouterPath.RATINGS_A_TEAM);
  };

  const numberOfPriorityAccesses = preferredWorkplaceData?.workplaceCountByReason
    ? preferredWorkplaceData.workplaceCountByReason.RATING +
      preferredWorkplaceData.workplaceCountByReason.INTERNAL_CRITERIA +
      preferredWorkplaceData.workplaceCountByReason.FAVORITE +
      aTeamWorkplacesCount
    : undefined;

  if (isPreferredWorkplaceLoading || isNullOrUndefined(numberOfPriorityAccesses)) {
    return <CardLoader />;
  }

  const priorityAccessCardProps: RatingCardProps = {
    title: "My Priority Access",
    description: `You have Priority Access at ${pluralizeWorkplace(numberOfPriorityAccesses)}. ${
      numberOfPriorityAccesses === 0
        ? "Learn more about how Priority Access works."
        : `View ${pluralizeWorkplaceWithThe(
            numberOfPriorityAccesses
          )} and learn more about how Priority Access works.`
    }`,
    buttonText: "See details",
    buttonOnClick: handlePriorityAccessClicked,
    scoreValue: numberOfPriorityAccesses,
    scoreColor: "shift-priority",
    testId: "priority-access",
  };
  const aTeamDescription =
    aTeamWorkplacesCount > 0
      ? `${pluralizeWorkplace(aTeamWorkplacesCount)} ${
          aTeamWorkplacesCount === 1 ? "has" : "have"
        } selected you for their A-Team due to your excellent performance! You'll receive Priority Access to their shifts.`
      : "no workplaces have selected you for their A-Team. If selected, you'll receive Priority Access to the workplace's shifts.";

  const aTeamCardProps: RatingCardProps = {
    title: "Priority Plus",
    description: aTeamDescription,
    buttonText: "See details",
    buttonOnClick: handleAteamClicked,
    scoreValue: aTeamWorkplacesCount,
    scoreColor: "shift-priority",
    testId: "a-team",
  };

  const cardProps =
    aTeamStatus === ATeamHcpStatus.A_TEAM ? aTeamCardProps : priorityAccessCardProps;

  return <RatingCard {...cardProps} />;
}
